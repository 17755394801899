import { filter } from 'rxjs/operators';
import { AutocompleteWithTagsComponent } from './../gen-inputs/autocomplete-with-tags/autocomplete-with-tags.component';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, QueryList, SimpleChange, ViewChild, ViewChildren, booleanAttribute } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AutoCompleteKeyUpObj,
  ConfigDefinitions,
  GenTableUpdate,
  StorePatient,
  TagItemTypes,
  yes_no_opts,
} from '@app/definitions/types';
import { ApiService, AvailablePostPaths, pluck } from '@app/services/api.service';
import { EventsService } from '@app/services/events.service';
import { GenTableService } from '@app/services/gen-table-service.service';
import { LangService } from '@app/services/lang.service';
import { ModalService } from '@app/services/modal.service';
import { SearchSortService } from '@app/services/search-sort.service';
import { ValidatorService } from '@app/services/validator.service';
import { StoreService } from '@app/services/store.service';
import { Subject } from 'rxjs';
import { TableGen } from '../definitions/types';
import { FieldGen, nonFocusFieldTypes } from './../definitions/types';
import { PermsService } from '@app/services/perms.service';
import { AutocompleteMultiComponent } from '@app/gen-inputs/autocomplete-multi/autocomplete-multi.component';
import * as moment from 'moment';

const nonLoadWithoutFilterTableNames: string[] = ["apotrppuses","medicpatients", "bankaccounts", "contactways", "diagppatients", "documents", "followups", "followupseries", "groupattends", "grouppatients", "invoices", "admininvoices", "labtestpatients","refererpatients", "medicpatients", "medicpatients2", "mixed_list", "nondrugpatients", "payorprices", "prescriptions", "user_programs"];

@Component({
  selector: 'app-gen-table',
  templateUrl: './gen-table.component.html',
  styleUrl: './gen-table.component.css'
})
export class GenTableComponent implements OnInit, TableGen {

  @Input() filterField: string = '';	//field to filter by
  @Input() filterFieldValue: any = null;	//value by which to filter the filter field
  @Input() filterFieldApiObject: any = null;	//any additional fields to send to the "get items" api
  @Input() filterFieldOrs: any = null;
  @Input() tableName: string = '';	//for the switch to bring fields,api call names etc
  @Input() addOnlyMode: boolean = false;	//display only "add row" (could be expanded to rest of rows) AND return the added row on close (using itemAdded function)
  @Input({transform:booleanAttribute}) isEditDisabled: boolean = false;	//ONLY show values, no edit/create/delete
  @Input() fuzzyName: string = "";	//on open, override NEW ROW "name" field with this value
  @Input({transform:booleanAttribute}) isCheckItems: boolean = false;	//do I want the rows to have checkboxes (updates the checked ids array using checkedItemsChanged)
  @Input({transform:booleanAttribute}) addOnlyRestOpened: boolean = false;	//keep rows open despite "add only mode"
  @Input() restrictedAddMode: boolean = false;	//if in add mode - don't show the filter field (if exists) and the list
  @Input() dataVariables: any = {};	//object containing variables and values to be used by misc func (like headerModalOpenBtns)
  @Input() newRowInjectedFields: any[] = [];	//each item is {name:"some field name",value:5}
  @Input() preChosenItems: any = null;	//attempts to "choose" items on open, contains the field to search value and array of values to choose { fieldName: "", values: [] };
  @Input() overrideUpperFields: string[] = [];

  @Output() checkedItemsChanged = new EventEmitter();	//when isCheckItems=true, updates the "checked ids" array to outsiders
  @Output() itemAdded = new EventEmitter();	//when addOnlyMode=true, once new row is submitted -  updates the new row to outsiders
  @Output() changeHappened = new EventEmitter();	//ANY change (CUD) happened, returns the entire objectCollection
  @Output() rowValidChange = new EventEmitter();	//ANY change (CUD) happened, returns the entire objectCollection

  @ViewChild("tableEl") tableEl: ElementRef;	//the html table el, used for printing
  @ViewChild("addRowBtn") addRowBtn: ElementRef;	//the "add" btn at the end of the "new row", used to focus on it
  @ViewChild("newRowTags") newRowTags: AutocompleteWithTagsComponent;
  @ViewChild("newRowAssoc") newRowAssoc: AutocompleteMultiComponent;
  @ViewChildren("focusableField") focusableFields: QueryList<any>;	//all fields that can receive focus, used to focus on them

  autocompleteCurrentValues: any = {};	//saves the current autocomplete fields' values, used to decide if to reset the id field related (example: changing a medic_name can reset the medic_id)

  chooseAllChecked: boolean = false;	//retains wether all checkboxes are considered checked or not
  tabindexDomain: number = 0;	//tabindex starting number (each row and field add to it, starting from it)
  hasFieldHiding: boolean = false;	//has fields that can be hidden
  isFieldHidingFloatOpen: boolean = false;	//is the field hiding float currently opened

  filterFieldApiObjectPreset: any = null;	//initial values for the api filter field object
  paginationObj: any = null;	//used in the pagination version - holds current pagination values
  collectionFields: FieldGen[] = [];	//the fields to be used in this table

  langProps: any = {};	//lang props to be used in the template (currently just "all_*")
  collectionName: string = '';	//name of the collection as it comes back from api calls
  apiCallPerms: any = { getCollection: '', saveField: '', deleteObject: '', saveNewRow: '' };	//the collection of api calls (will be updated by values per tableName from api, then loaded through table-gen.service)
  defaultNewRow: any = {};	//default values for a new row
  openNewRowOnInit: boolean = false;	//will open a new row when this component finishes startup
  hasFirstGetItemsHappened: boolean = false;	//flags whether first "get_items" happened (used to open new row or choose items on startup)

  expanded: any = null;	//an object (currently containing the upperField) to decide if to only show the upperFields, and the rest are hidden (expanded via down-arrow click) (when null - all fields are always shown)

  numberFields: string[] = [];	//saves all the number fields (remembered for sorting)
  siteDataSortFields: any[] = [];	//saves all the number fields (remembered for sorting)
  dateFields: string[] = [];	//saves all the date fields (remembered for sorting)
  objectCollection: any[] = [];	//the actual objects loaded for this table
  objectCollectionShown: any[] = [];	//the SHOWN object (some are hidden by filters, searches)
  searchableFields: string[] = ['name'];	//the fields that will be searches in each row, when user types searching terms (the search function will only look at THEIR values to determine matching)
  headerModalOpenBtns: any[] = [];	//btns that appear in the header and open modals (each object defines the appearence, modal name and fields to pass to the modal)
  filterFields: any[] = [];	//filters for shown rows (each in a buttn group, defines the options, default values etc)
  currentFilterValues: any = {};	//object containing the current value for each filter (changes as user clicks other options, resets) (affects isEditDisabled if filters are forcing a value check)
  duplicateWarningFields: string[] = [];	//fields that would cause a warning (and request to confirm) if a value is trying to be saved that already exists in a different row in this field
  updateRowOverride: AvailablePostPaths=null;	//update entire row instead of individual fields (contains the api function's name)
  sortField: string = 'name';	//current field to sort by
  searchStr: string = '';	//current search string in the search input
  sortIsAsc: boolean = true;	//flag to remember if current sort is ASC (false is DESC) flips on same column click, resets on different column click

  isNewOpen: boolean = false;	//flag to remember if new row is open and visible
  isLoading: boolean = true;	//flag to show message while loading
  newRow: any = {};	//object containing the new row (will get fields and default values on creation)
  isFormSubmittable: boolean = false;	//can submit (controls disabled in the submit button)
  searchSubj = new Subject();	//a subject listening to searching changes, only works in pagination version
  escapeKeySubj = null;	//listen to keyboard ESC, reset filters and search
  docClickSubj = null;	//listen to doc click
  currentSearch: string = "";	//holds the current search string (changes by the input or a reset)
  ageSearch: string = "";	//holds the current search string (changes by the input or a reset)

  updateTableSubject:any=null;
  refreshTableSubject:any=null;
  reInitTableSubject:any=null;
  lockedPatientsSubs:any=null;
  
  isClientPagi:boolean=false;
  clientPagiSize:number=30;
  clientPagiObj:any={curPage:1,lastPage:1};

  isAllExpanded: boolean = false;	//flag for whether all expanded items are opened

  mobileUpperFields: string[] = [];	//created uppser fields BUT ONLY if currently in mobile mode

  initHappened: boolean = false;	//remembers if the first ngOnInit already happened (to present multiple functions calling init (ngOnInit,changes to variable, changes to route))

  addOnlyVis: boolean = false;

  upperCollectionFields: any = [];	//defaults to all fields. in expanded mode - the fields that appear as the top, visible ones.
  lowerCollectionFields: any = [];	//defaults to empty. in expanded mode - only the fields that are only visible when row is expanded
  isSaving: boolean = false;	//flag for "in saving" of the new row (for spinner and disabling the new row save)
  isSearching: boolean = false;
  hasAssocFilter:boolean=false;
  hasTagsFilter:boolean=false;
  isSendingMulti: boolean = false;

  downloadAndEmailTypes: string[] = ['document', 'admininvoice', 'invoice', 'justinvoice', 'receipt', 'proforma', 'prescription', 'document_copy', 'admininvoice_copy', 'invoice_copy', 'justinvoice_copy', 'receipt_copy', 'proforma_copy', 'prescription_copy'];	//all types that will enable download/mail-to btns (if row has a different value in this field, it will remain empty)

  perms: string[] = [];	//perms that prohibit using this table

  avoidNewRow:boolean=false;

  excludeIds:number[]=[];
  filterAssocUserIds:number[]=[];
  filterTagIds:number[]=[];

  inquiry_status_id:number=0;
  inquiry_statuses_names:any[]=[];
  inquiry_statuses_names_obj:any={};

  addRowIsByPlus:boolean=false;
  isMobile: boolean = false;
  hasCheckedItems: boolean = false;
  hideExport: boolean = false;
  expandOnLoad:boolean=false;

  
  tagFilterType:TagItemTypes="patient";
  period:string="this_month";


  constructor(protected apiService: ApiService, public lang: LangService, public validator: ValidatorService, protected searchSortService: SearchSortService, public modalService: ModalService, protected route: ActivatedRoute, protected genTableService: GenTableService, protected eventsService: EventsService, protected store: StoreService, public permsService: PermsService,protected zone:NgZone) { }

  ngOnChanges(changes: SimpleChange) {	//follow changes to input values. if any changes - run initRun
    let changeableFields = ['tableName', 'filterField', 'filterFieldValue'];
    for (let field of changeableFields) {
      if (changes[field]) {
        this.initRun();
        return;
      }
    }
  }

  ngOnInit() {	//start the component - subscribe to events and run init

    this.isMobile = this.eventsService.isMobile;
    this.excludeIds = this.store.getNonClinicalSubUserIds();
    this.escapeKeySubj = this.eventsService.escapeKeySubj.subscribe(() => {	//subscribe to ESC key - reset search and filter
      this.resetSearchAndFilter();
    });

    this.docClickSubj = this.eventsService.docClickSubject.subscribe(() => {
      this.objectCollection.forEach(row => row.actionsOpen = false);
      this.isFieldHidingFloatOpen=false;
    })

    this.initHappened = true;	//flag as initHappened (prevents multiple initRuns in some cases)

    if (this.tableName) {	//if has tableName - run init
      this.initRun();
    }

    // this.route.paramMap.subscribe(paramMap => {	//on changes to url, only listen to tableName changes (ex. clicking on a menu when already in a (different) table)
    // 	if (!paramMap.get('tableName')) {
    // 		return;
    // 	}
    // 	this.tableName = paramMap.get('tableName');
    // 	this.initRun();	//run init
    // });
  }

  initRun() {	//run all init functionalities

    if (!this.initHappened) {	//if ngOnInit hasn't happened - wait for it
      return;
    }

    if (nonLoadWithoutFilterTableNames.includes(this.tableName) && !this.filterField && !this.filterFieldApiObject) {	//if table is one that can only be opened with filters - make sure it has one
      throw new Error("Illegal Table " + this.tableName);
    }


    //reset all fields
    this.numberFields = [];
    this.siteDataSortFields = [];
    this.updateTableSubject=null;
    this.refreshTableSubject=null;
    this.reInitTableSubject=null;
    this.lockedPatientsSubs=null;
    this.hasFirstGetItemsHappened = false;
    this.dateFields = [];
    this.autocompleteCurrentValues = {};
    this.objectCollection = [];
    this.mobileUpperFields = [];
    this.openNewRowOnInit = false;
    this.objectCollectionShown = [];
    this.searchableFields = ['name'];
    this.filterFieldApiObjectPreset = null;
    this.duplicateWarningFields = [];
    this.sortField = 'name';
    this.tabindexDomain = 0;
    this.expanded = null;
    this.filterFields = [];
    this.hasFieldHiding = false;
    this.isFieldHidingFloatOpen = false;

    this.avoidNewRow=false;

    this.currentSearch = "";
    this.headerModalOpenBtns = [];
    this.isAllExpanded = false;
    this.updateRowOverride = null;
    this.searchStr = '';
    this.chooseAllChecked = false;
    this.sortIsAsc = true;
    this.isNewOpen = false;
    this.isLoading = true;
    this.currentFilterValues = {};
    this.newRow = {};
    this.isFormSubmittable = false;
    this.paginationObj = null;

    this.upperCollectionFields = [];
    this.lowerCollectionFields = [];
    this.isSaving = false;
    this.isSearching = false;
    this.isSendingMulti = false;
    this.perms = [];
    // this.isTableScrollable=(this.tableName=="patients" &&  !this.eventsService.isMobile);
    this.isTableScrollable=false;
    this.hasAssocFilter=false;
    this.hasTagsFilter=false;
    this.filterAssocUserIds=[];
    this.inquiry_status_id=0;
    this.inquiry_statuses_names=this.store.getInquiryStatusNamesAsArray();
    this.inquiry_statuses_names_obj=this.store.getInquiryStatusNamesAsObj();
    this.addRowIsByPlus=false;
    this.ageSearch="";
    this.hasCheckedItems=false;
    this.hideExport=false;
    this.expandOnLoad=false;
    this.tagFilterType="patient";
    this.period="this_month";
    this.isClientPagi=false;
    this.clientPagiObj={curPage:1,lastPage:1};

    

    this.genTableService.switchTableNames(this);	//load all variables from the service switch

    if(this.overrideUpperFields?.length){
      console.log(this.overrideUpperFields,this.expanded);

      this.expanded.upperFields=this.overrideUpperFields;
    }

    this.eventsService.updateTitle(this.lang.getVal(this.langProps.allName));

    const missingPerm = this.perms.some(perm => !this.permsService.perms[perm]);
    //console.log((this.permsService.perms));
    if (missingPerm) {
      throw new Error("Perms Missing Illegal Table " + this.tableName);
    }



    //deal with assoc perms
    switch (this.tableName) {
      case "patients":
      case "inquirers":
        this.isClientPagi=this.store.getCliniqDataOrExit().pagination_in_big_tables==="yes";
        if(this.permsService.owner_has_users &&  !this.permsService?.perms?.administrative && !this.permsService?.perms?.add_new_patient){
          // this.headerModalOpenBtns=this.headerModalOpenBtns.filter(it=>it.modal!=InsertPatientFormComponent);
          this.headerModalOpenBtns=this.headerModalOpenBtns.filter(it=>it.modal!="insert-patient-form");
        }
        const obj=this.store.getCliniqDataOrExit().insert_patient_template;
        if(obj){
          this.collectionFields = this.collectionFields.filter(field =>{
            const {fieldName}=field;
            return !(fieldName in obj) || obj[fieldName]
          });
        }
      case "groups":
      case "payorpatients":
        if (!this.permsService.perms?.administrative || !this.permsService.owner_has_users) {
          this.collectionFields = this.collectionFields.filter(field => field.fieldName != "assoc");
        }
        break;
      case "expenses":
        if (!this.permsService.owner_has_users) {
          this.collectionFields = this.collectionFields.filter(field => field.fieldName != "user_id");
        }
        break;
      case "grouppatients":
        if(this.filterFieldValue){
          const group=this.store.getSiteDataTable("patients").find(it=>it.is_group=="yes" && it.id==this.filterFieldValue);
          if(group?.charge_mode!="by_participant"){
            this.collectionFields = this.collectionFields.filter(field => field.fieldName != "invoice");
          }
        }

        break;
        case "contactways":
          if(this.filterFieldApiObject?.contactway_identifier==="email"){
            this.collectionFields = this.collectionFields.filter(field => field.fieldName != "whatsapp");
          }
        break;
    }
    if(!this.permsService.owner_has_users){
      this.hasAssocFilter=false;
    }
    const cc=this.store.getCliniqDataOrExit();
    if(cc?.only_therapist_id){
      this.hasAssocFilter=false;
    }


    if (this.eventsService.isMobile || this.eventsService.isTouch) {	//mobile specific init
      // if (!this.mobileUpperFields.length) {
      // 	this.mobileUpperFields = this.collectionFields.slice(0, 2).map(fieldObj => fieldObj.fieldName);
      // }
      // this.expanded = { upperFields: this.mobileUpperFields };
      if (this.mobileUpperFields.length) {	//if mobileUpperFields, force expand and upper fields
        this.expanded = { upperFields: this.mobileUpperFields };
      }

      //go over all fields and override the width/minWidth with mobile version if exists
      this.collectionFields.forEach(fieldObj => {
        if (fieldObj?.attrs?.mobileWidth) {
          fieldObj.attrs.width = fieldObj?.attrs?.mobileWidth;
        }
        if (fieldObj?.attrs?.mobileMinWidth) {
          fieldObj.attrs.minWidth = fieldObj?.attrs?.mobileMinWidth;
        }
      })
    }

    if (this.expanded) {	//if expanded object exists
      this.collectionFields.forEach(fieldObj => {	//iterate fields and assign to upper or lower

        let upperFields=this.expanded.upperFields;
        if(this.expanded.lgAddedUpperFields?.length && window.innerWidth > 1400 ){
          upperFields=[...upperFields,...this.expanded.lgAddedUpperFields];
        }


        if (upperFields.includes(fieldObj.fieldName)) {	//if found in expanded upper fields array - assign to upper
          this.upperCollectionFields.push(fieldObj);
        }
        else {	//not in expanded upper fields - assign to lower
          this.lowerCollectionFields.push(fieldObj);
        }
      })
    }
    else {	//not expanded
      this.upperCollectionFields = this.collectionFields;	//assign all fields to upper
    }

    if (this.restrictedAddMode && this.filterField) {	//if restrictedAddMode and filterField - remove field from upper and lower fields
      this.upperCollectionFields = this.upperCollectionFields.filter(fieldObj => fieldObj.fieldName != this.filterField);
      this.lowerCollectionFields = this.lowerCollectionFields.filter(fieldObj => fieldObj.fieldName != this.filterField);
    }

    this.filterFields.forEach(filterObj => {	//iterate the filter fields and init the current filter values
      this.currentFilterValues[filterObj.fieldName] = filterObj.initialValue || filterObj.defaultValue || "-1";
    })

    this.collectionFields.forEach(async fieldObj => {	//iterate fields and add to relevant arrays and load external collections
      if (fieldObj.isSiteDataSortField) {	//if number field - add to the number fields array
        this.siteDataSortFields.push(fieldObj);
      }
      if (fieldObj.isNumberField) {	//if number field - add to the number fields array
        this.numberFields.push(fieldObj.fieldName);
      }
      if (fieldObj.type === "date") {	//if date type - add to the date fields array
        this.dateFields.push(fieldObj.fieldName);
      }
      if (fieldObj?.genTblToLoad) {	//if has genTblToLoad - load table into options collection
        const res:any=await this.store.get_gen_items(fieldObj.genTblToLoad);
        // console.log(res);
        const arr = res?.col || res[fieldObj.genTblToLoad];
        fieldObj.optionsCollection = arr.map(it => ({ value: it.id, name: it.name }));	//load collection as {value,name} objects
      }

      if (fieldObj.type == "fuzzy") {
        fieldObj.fuzzyArr = [...fieldObj.attrs.fuzzySearchableClasses];
        // if (fieldObj.attrs?.otherFieldToUse) {
        // 	fieldObj.fuzzyArr = [...fieldObj.fuzzyArr, ...fieldObj.attrs.otherFieldToUse.fuzzySearchableClasses];
        // }
      }

      //if fields has staticOnPerms and user has o perms - change to static and get value
      if (fieldObj?.attrs?.staticOnPerms) {
        const missingPerm = fieldObj.attrs.staticOnPerms.some(perm => !this.permsService.perms[perm]);
        if (missingPerm) {
          fieldObj.attrs.staticOnEdit = true;
        }
      }

    });
    this.hasFieldHiding = this.collectionFields.some(f => f.isHideable);	//if any fields are hideable - flag

    this.addOnlyVis = this.addOnlyMode;

    const show_insert_row=this.store.getCliniqDataOrExit()?.show_insert_row=="yes";

    if (this.addOnlyRestOpened || (!this.eventsService.isMobile && show_insert_row && !this.addRowIsByPlus && (this.addOnlyMode || this.openNewRowOnInit))) {
      this.prepNewRow();
      if (this.fuzzyName) {
        this.newRow.name = this.fuzzyName;
        this.validateEntireForm();
      }
      this.processNewRowInjectedFields();
      if (this.addOnlyRestOpened) {
        this.addOnlyVis = false;
      }
    }



    //run the pagination version inits
    this.pagiInit();
    this.subscribeToSearch();


    this.apiService.sendApiGetPerms(this.getItems.bind(this));

    this.updateTableSubject=this.store.updateTableSubject.subscribe((obj: GenTableUpdate) => {
      if(this.tableName==obj.tableName){
        if(obj.rows?.length){
          obj.rows.forEach(incomingRow=>{
            const tableRow=this.objectCollection.find(row=>row.id==incomingRow[obj.idField]);
            if(tableRow){
              obj.fieldsToUpdate.forEach(updateField=>{
                tableRow[updateField.genTableFieldName]=incomingRow[updateField.incomingRowFieldName];
                console.log("updated",tableRow,updateField.genTableFieldName,"to",updateField.incomingRowFieldName,incomingRow[updateField.incomingRowFieldName]);

              });

            }
          })
        }
      }

    });
    this.refreshTableSubject=this.store.refreshTableSubject.subscribe((tableName: string) => {
      if(this.tableName==tableName){
        this.refreshItems();
      }
    });
    this.reInitTableSubject=this.store.reInitTableSubject.subscribe((tableName: string) => {
      console.log(tableName);
      console.log(this.tableName);

      if(this.tableName==tableName){
        this.initRun();
      }
    });


    if(["groups","patients","inquirers"].includes(this.tableName)){
      this.lockedPatientsSubs=this.store.lockedPatientsSubject.subscribe((res:any)=>{
        if(Array.isArray(res)){
          this.objectCollection.forEach(row=>{
            row.is_locked=res.includes(row.id);
          })
          // console.log(res);

        }
      })
    }

  }

  pagiInit() { }	//used by pagination version, resets values


  runFiltersPost() {	//after filters are applied, research using them (in pagi - gets from server)
    this.search();
  }

  processNewRowInjectedFields() {	//if there are injected fields inputed (ex. in fuzzy input and then clicking the plus to open an add modal) - add them to the new row (will only happen on init)
    console.log(this.newRowInjectedFields);

    if (this.newRowInjectedFields?.length) {
      this.newRowInjectedFields.forEach(item => {
        this.newRow[item.name] = item.value;
      })
      this.validateEntireForm();
    }
  }
  colorClear(row: any, fieldObj: any, isNewRow: boolean) {	//on clicking a color field's reset btn. resets the string then processed like any regular field
    row[fieldObj.fieldName] = "";
    if (isNewRow) {
      this.validateEntireForm();
    }
    else {
      this.saveField(row, fieldObj);
    }
  }

  runFilters() {	//update whether "isEditDisabled" has changed and run the post func
    this.filterFields
      .filter(filterObj => filterObj.canEditOnValue !== undefined)	//only the filter fields that have canEditOnValue
      .forEach(filterObj => {
        let canEdit = (filterObj.canEditOnValue === this.currentFilterValues[filterObj.fieldName]);	//see if the canEditOnValue matches with this filter's CURRENT value
        this.isEditDisabled = !canEdit;	//if different value - edit is disabled
      });
    this.runFiltersPost();	//post filters behaviours (different for regular and pagi)

  }

  updFilesObj(row: any, fieldName: string, files: FileList = null) {
    if (!row.filesObj) {
      row.filesObj = {};
    }
    row.filesObj[fieldName] = (files ? files.item(0) : "");
  }

  openExpandedRow(ev: MouseEvent, row: any) {
    ev.stopImmediatePropagation();
    row.isExpanded = !row.isExpanded;
    this.changeItemsShownNonScroll(this.scrollTop);
  }
  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;
    this.objectCollection.forEach(row => row.isExpanded = this.isAllExpanded);
    this.objectCollectionShown.forEach(row => row.isExpanded = this.isAllExpanded);
  }


  subscribeToSearch() { }	//used py pagination version, subscribes to the search Subject to perform search and get items etc

  ngOnDestroy() {	//cleanup of the subjects (listening to ESC key, listening to search changes in the pagination version)
    this.escapeKeySubj.unsubscribe();
    this.searchSubj.unsubscribe();
    this.docClickSubj.unsubscribe();
    if(this.updateTableSubject){
      this.updateTableSubject.unsubscribe();
    }
    if(this.refreshTableSubject){
      this.refreshTableSubject.unsubscribe();
    }
    if(this.lockedPatientsSubs){
      this.lockedPatientsSubs.unsubscribe();
    }
  }

  expandDateFields() {
    if (!this.dateFields.length) { return; }
    this.objectCollection.forEach(row => {
      this.dateFields.forEach(fieldName => {
        this.genTableService.updDateField(row, fieldName,row[fieldName]);
      })
    })
  }
  updateDateField(row: any, fieldObj: any) {
    this.genTableService.updDateField(row, fieldObj.fieldName, row[fieldObj.fieldName]);
  }
  refreshItems(){
    this.sortField="id";
    this.sortIsAsc=false;
    this.getItems();
  }
  async getItems() {
    this.genTableService.attachApiCallPerms(this);

    let getCallObj: any = {};
    if (this.filterField) {
      getCallObj.filterField = this.filterField;
      getCallObj.filterFieldValue = this.filterFieldValue;
    }

    let filterFieldApiObject = {};
    if (this.filterFieldApiObjectPreset) {
      filterFieldApiObject = { ...filterFieldApiObject, ...this.filterFieldApiObjectPreset };
    }
    if (this.filterFieldApiObject) {
      filterFieldApiObject = { ...filterFieldApiObject, ...this.filterFieldApiObject };
    }
    if (Object.keys(filterFieldApiObject).length) {
      getCallObj.filterFieldApiObject = filterFieldApiObject;
    }
    this.isLoading = true;
    this.emptyShownRows();

    // console.log(getCallObj);
    const res:any=await this.store.get_gen_items(this.tableName, getCallObj);
    this.objectCollection = res[this.collectionName];
    this.isLoading = false;

    if (!this.objectCollection) { return; }

    if(this.filterFieldOrs){
      Object.keys(this.filterFieldOrs).forEach(key=>{
        const vals=this.filterFieldOrs[key];
        this.objectCollection=this.objectCollection.filter(it=>{
          return vals.includes(it[key]);
        })
      })
    }

    // if (this.tableName == "patients") {
    // 	this.store.loadOneSiteDataTable("patients", res.patients);
    // }

    // console.log(this.objectCollection)
    // console.log(this.hasFirstGetItemsHappened);

    if (!this.hasFirstGetItemsHappened) {
      if (!this.objectCollection.length) {
        this.prepNewRow();
        this.processNewRowInjectedFields();
      }

      // console.log(this.preChosenItems);
      if (this.preChosenItems) {
        //console.log(this.preChosenItems);
        const fieldName = this.preChosenItems.fieldName;
        const values = this.preChosenItems.values;
        this.objectCollection.forEach(row => {
          const value = row[fieldName];
          if (values.includes(value)) {
            row.checked = true;
          }
          //console.log(row);
        });
        this.emitCheckedItemsChanged();

      }
      else{
        if(this.isCheckItems){
          if(this.tableName=="contactways"){
            this.objectCollection.forEach(row => {
              if (row?.main=="yes") {
                row.checked = true;
              }
              //console.log(row);
            });
            this.emitCheckedItemsChanged();
          }
        }
      }


    }
    if(this.expandOnLoad && this.eventsService.isMobile){
      this.expandAll();
    }

    //this.hasFirstGetItemsHappened = true;
    // this.apiService.decryptInCollection(this.objectCollection);
    this.objectCollection.forEach(row => {
      row.errors = {};
      this.prepRow(row)
      this.htmlNormalization(row);

    });
    this.expandDateFields();
    this.objectCollectionShown = this.objectCollection;
    this.runFilters();
    // if(this.objectCollectionShown.length>100){
    // 	this.objectCollectionShown=this.objectCollectionShown.filter((it,ind)=>ind<100);
    // }
  }


  clearSearch(){
    this.currentSearch="";
    this.search();
  }

  search() {
    this.isSearching=true;
    this.searchSortService.search(this, this.currentSearch, 'objectCollectionShown', 'objectCollection');

    if(this.hasAssocFilter && this.filterAssocUserIds.length){
      this.objectCollectionShown=this.objectCollectionShown.filter(row=>{
        for(let userId of this.filterAssocUserIds){
          if(!row.assoc.includes(userId)){
            return false;
          }
        }
        return true;
      });
    }
    if(this.hasTagsFilter && this.filterTagIds.length){
      this.objectCollectionShown=this.objectCollectionShown.filter(row=>{

        const tagIds = this.store.getSiteDataTable("object_to_tags").filter(o2t => o2t.item_type == this.tagFilterType && o2t.item_id == row.id).map(row => row.tag_id);
        // console.log(row.name);
        //console.log(tagIds);

        for(let tagId of this.filterTagIds){
          if(!tagIds.includes(tagId)){
            return false;
          }
        }
        return true;
      });
    }

    if(this.tableName=="inquirers" && Number(this.inquiry_status_id)){
      this.objectCollectionShown=this.objectCollectionShown.filter(row=>row.inquiry_status_id==this.inquiry_status_id);
    }


    if(["patients","inquirers"].includes(this.tableName) && this.ageSearch){
      const num=Number(this.ageSearch);
      if(!isNaN(num)){

        this.objectCollectionShown=this.objectCollectionShown.filter(row=>{
          if(!row?.year_of_birth){return false}

          const age=moment.utc().diff(moment.utc(row.year_of_birth),"years");
          return age==num;
        })
      }
    }
    if(this.tableName=="expenses"){
      const [from,to]=this.store.getPriodFromTo(this.period);

      if(from){
        const dateFrom=new Date(from);
        this.objectCollectionShown=this.objectCollectionShown.filter(row=>{
          return dateFrom<=row[this.store.getDateExpandName("date")];
        });
      }
      if(to){
        const dateTo=new Date(to);
        this.objectCollectionShown=this.objectCollectionShown.filter(row=>{
          return dateTo>=row[this.store.getDateExpandName("date")];
        });
      }
    }

    this.isSearching=false;

    this.searchSortService.sort(this, 'objectCollectionShown', this.sortField, true);
    this.changeItemsShownNonScroll();
    this.resetClientPagiObj();
  }
  resetClientPagiObj(){
    this.clientPagiObj={
      curPage:1,
      lastPage:this.objectCollectionShown.length ? Math.floor( (this.objectCollectionShown.length-1) / this.clientPagiSize )+1 : 1,
    }
  }

  sort(fieldName: string, repeatSort: boolean = false) {
    let fieldObj = this.collectionFields.find(it => it.fieldName == fieldName);
    if (fieldObj && !fieldObj.isSortable) {
      return;
    }
    this.searchSortService.sort(this, 'objectCollectionShown', fieldName, repeatSort);
    this.changeItemsShownNonScroll();
    this.resetClientPagiObj();
  }

  //new row string -> choose id -> change to string -> choose id ->choose string
  //cur row (string) - string changes
  //cur row (id) - stops
  //other fuzzy unaffected

  autocompleteKeyUp(obj: AutoCompleteKeyUpObj, curRow: any, fieldObj: any, isNewRow: boolean) {	//edits row fields according to changes to autocomplete BY TYPING
    const newValue = obj.str;

    //console.log(isNewRow, fieldObj.attrs.idNameConnection?.idField, curRow[fieldObj.attrs.idNameConnection?.idField])
    if (!isNewRow && curRow[fieldObj.attrs.idNameConnection?.idField]) {	//UPDATE and has idNameConnection and has a value in the id field - EXIT the function
      return;
    }


    if (fieldObj.attrs.idNameConnection) {	//this field has idNameConnection
      if (newValue != this.autocompleteCurrentValues[fieldObj.fieldName]) {	//the new value is different than the last CHOSEN value - empty the ID field
        curRow[fieldObj.attrs.idNameConnection.idField] = 0;
      }
      curRow[fieldObj.fieldName] = newValue;	//update the actual NAME field

      if (isNewRow) {	//send to validations/save
        this.validateEntireForm(curRow, fieldObj)
      }
      else {
        this.validateAndSaveField(curRow, fieldObj)
      }
    }
    else {	//no idNameConnection
      if (!obj.isIdenticalToPreviousValue) {	//not the SAME value (same == for example, if user only used an arrow)
        curRow[fieldObj.fieldName] = null;	//update this field

        if(this.tableName=="grouppatients"  && fieldObj.fieldName=="patient_id"){
          curRow.user_id=null;
        }

        // if (fieldObj.attrs?.otherFieldToUse) {
        // 	curRow[fieldObj.attrs?.otherFieldToUse.fieldName] = null;
        // }

        if (isNewRow) {	//send to validations/save
          this.validateEntireForm(curRow, fieldObj)
        }
        else {
          this.validateAndSaveField(curRow, fieldObj)
        }
      }
    }
  }

  tryToFocusOnNextField(curRow: any, fieldObj: any, isNewRow: boolean) {	//obj:any,
    let nextFocusRow = curRow;
    let nextFocusFieldObj = null;
    if (isNewRow) {

      //start focus code
      // if (!obj.id) { return; }
      // console.log("new row");
      //what's the next focus?
      let nextTab = (fieldObj.tabindex || -1) + 1;
      let nextField = this.collectionFields.find(f => f.tabindex == nextTab);
      // console.log(nextTab,this.collectionFields.find(f=>f.tabindex==nextTab));

      if (nextField) {
        // console.log("nextField");
        nextFocusFieldObj = nextField;
      }
      else {
        // console.log("not nextField");
        if (this.addRowBtn?.nativeElement) {
          // console.log("add btn");
          this.addRowBtn.nativeElement.focus();
        }
        //go to add row btn
        return;
      }
    }
    else {


      //start focus code
      // if (!obj.id) { return; }
      // console.log("existing");
      //find next field that isn't in "non-focus-types"
      let myIndex = this.collectionFields.findIndex(f => f.fieldName == fieldObj.fieldName);
      let nextField = this.collectionFields.filter((f, index) => index > myIndex && !nonFocusFieldTypes.includes(f.type))[0];

      if (nextField) {
        // console.log("nextField");
        nextFocusFieldObj = nextField;
      }
      else {	//next row - find first focusable field
        // console.log("not nextField");
        let rowIndex = this.objectCollectionShown.findIndex(r => r.id == curRow.id);
        // console.log(rowIndex,this.objectCollectionShown);
        let nextRow = this.objectCollectionShown[rowIndex + 1];
        if (nextRow) {
          // console.log("next row");
          nextFocusRow = nextRow;
          let nextField = this.collectionFields.filter(f => !nonFocusFieldTypes.includes(f.type))[0];
          if (nextField) {
            nextFocusFieldObj = nextField;
          }
          else {
            return;
          }
        }
        else {
          // console.log("not next row");
          return;
        }

      }

    }
    // console.log(nextFocusRow,nextFocusFieldObj);
    // console.log(this.focusableFields,(nextFocusRow.id || 0)+'_'+nextFocusFieldObj.fieldName);
    this.focusOnRowField(nextFocusRow, nextFocusFieldObj);
  }

  autocompleteChoice(obj: any, curRow: any, fieldObj: any, isNewRow: boolean) {

    console.log(curRow,obj);
    if (fieldObj.attrs.idNameConnection) {
      if (obj?.id) {
        curRow[fieldObj.attrs.idNameConnection.idField] = obj.id;
        curRow[fieldObj.fieldName] = obj.name;
        this.autocompleteCurrentValues[fieldObj.fieldName] = obj.name;
      }
      else {
        curRow[fieldObj.attrs.idNameConnection.idField] = 0;
        curRow[fieldObj.fieldName] = "";
      }
    }
    if(this.tableName=="medicpatients" && isNewRow){
      curRow.atc5= obj?.id ? obj.atc5_name : "";
      console.log(curRow);
    }


    // console.log(fieldObj?.attrs?.fuzzySearchObj?.otherFieldsToUpdate);

    if (fieldObj?.attrs?.fuzzySearchObj?.otherFieldsToUpdate) {
      //console.log(fieldObj?.attrs?.fuzzySearchObj?.otherFieldsToUpdate, obj);
      //console.log(1111);
      fieldObj.attrs.fuzzySearchObj.otherFieldsToUpdate.forEach(field => {
        curRow[field] = (obj?.id ? obj[field] : "");
        //console.log(field, curRow[field]);
        if (!isNewRow) {
          const otherFieldObj = this.collectionFields.find(f => f.fieldName == field);
          this.validateAndSaveField(curRow, otherFieldObj);
        }
      })
    }

    // if (fieldObj.attrs?.otherFieldToUse) {
    // 	curRow[fieldObj.fieldName] = 0;
    // 	curRow[fieldObj.attrs.otherFieldToUse.fieldName] = 0;
    // 	if (fieldObj.attrs.otherFieldToUse.fuzzySearchableClasses.includes(obj.typeClassName)) {
    // 		curRow[fieldObj.attrs.otherFieldToUse.fieldName] = obj?.id || 0;
    // 	}
    // 	else {
    // 		curRow[fieldObj.fieldName] = obj?.id || 0;
    // 	}
    // }
    if(this.tableName=="grouppatients" && fieldObj.fieldName=="patient_id"){
      curRow.patient_id=0;
      curRow.user_id=0;
      if(obj && obj.typeClassName=="sub_users"){
        curRow.user_id=obj?.id || 0;
      }
      else{
        curRow.patient_id=obj?.id || 0;
      }
      //console.log(curRow);
    }



    if (isNewRow) {
      this.validateEntireForm(curRow, fieldObj)
    }
    else {
      this.validateAndSaveField(curRow, fieldObj)
    }
    if (obj.id) {
      this.tryToFocusOnNextField(curRow, fieldObj, isNewRow);
    }


  }
  newRowTagsChoice(arrObjs: any[], fieldObj: any) {
    this.newRow[fieldObj.fieldName] = arrObjs.map(it => it.id);
  }

  focusOnRowField(row: any, fieldObj: any) {
    if (!this.focusableFields) { return; }
    if (this.eventsService.isTouch) { return }
    let element = this.focusableFields.find(component => component.dataRowField == (row.id || 0) + '_' + fieldObj.fieldName);
    if (element) {
      element.focus();
    }
  }

  // validate()

  validateAndSaveField(curRow: any, fieldObj: any) {
    console.log("here");
    
    if(this.updateRowOverride){
      this.collectionFields.forEach(f=>{
        this.validateField(curRow, f.fieldName);
      });
      return;
    }
    this.validateField(curRow, fieldObj.fieldName);
    if(this.headturesSpecialValidation(curRow,fieldObj,true)){return;}
    this.saveField(curRow, fieldObj);
  }

  resetSearchAndFilter() {
    this.currentSearch = "";
    this.resetFilters();
    this.search();
  }
  resetFilters(doSearch = false) {

    this.filterFields.forEach(filterObj => {
      this.currentFilterValues[filterObj.fieldName] = filterObj.defaultValue || "-1";
    })
    if (doSearch) {
      this.search();
    }

  }
  htmlNormalization(row: any) {
    if (row.html_content) {
      row.html_content_normalized = this.store.normalizeHtml(row.html_content);
    }
  }

  headturesSpecialValidation(curRow:any,fieldObj:any,doSave:boolean=false){ //if returns true - saving should be stopped
    if(this.tableName!=="headtures"){return false;}
    const fields=["header_default","lang","header_text"];
    if(!fields.includes(fieldObj.fieldName)){return false;}
    let hasAnyErrors=false;
    fields.forEach(fName=>{
      const field = this.collectionFields.find(it => it.fieldName == fName);
      this.validator.validateField(curRow, curRow.errors, field.fieldName, field.validationRules, this.objectCollection);
      if(curRow.errors[fName]){
        hasAnyErrors=true;
      }
    });
    if(doSave && !hasAnyErrors){
      fields.forEach(fName=>{
        const field = this.collectionFields.find(it => it.fieldName == fName);
        this.saveField(curRow, field);
      });
    }
    return true;
  }


  validateField(curRow: any, fieldName: string, isNewRow: boolean = false) {
    if (isNewRow) {
      return this.validateEntireForm();
    }
    this.rowValidChange.emit(null)
    let fieldObj = this.collectionFields.find(it => it.fieldName == fieldName);
    //console.log(fieldObj);
    //console.log(fieldObj.attrs);



    if (fieldObj && !fieldObj.validationRules) {
      return;
    }
    this.validator.validateField(curRow, curRow.errors, fieldName, fieldObj.validationRules, this.objectCollection);


    this.headturesSpecialValidation(curRow,fieldObj);

    // if(this.tableName=="headtures"){
    //   const fields=["header_default","lang","header_text"];
    //   if(fields.includes(fieldName)){

    //     let hasAnyErrors=false;
    //     fields.forEach(fName=>{
    //       const field = this.collectionFields.find(it => it.fieldName == fName);
    //       this.validator.validateField(curRow, curRow.errors, field.fieldName, field.validationRules, this.objectCollection);
    //       if(curRow.errors[fName]){
    //         hasAnyErrors=true;
    //       }
    //     })
    //     if(curRow.errors?.header_default || curRow.errors?.lang){
    //       // curRow.errors.header_default=this.lang.getVal("field_must_be_unique");
    //     }

    //     // const otherField = this.collectionFields.find(it => it.fieldName == fieldObj.attrs.joinedWithField);
    //     //console.log(otherField.fieldName);

    //     //if EITHER field has errors - both have errors
    //     // if (curRow.errors[fieldObj.fieldName] || curRow.errors[otherField.fieldName]) {
    //     //   curRow.errors[fieldObj.fieldName] = this.lang.getVal("field_must_be_unique");
    //     //   curRow.errors[otherField.fieldName] = this.lang.getVal("field_must_be_unique");
    //     // }
    //     //console.log(curRow.errors);
    //     //console.log("saving " + fieldObj.attrs.joinedWithField);
    //     //run save for other field
    //     // this.saveField(curRow, otherField);
    //   }

    // }

    // if (fieldObj.attrs?.joinedWithField) {

    // 	const otherField = this.collectionFields.find(it => it.fieldName == fieldObj.attrs.joinedWithField);
    // 	//console.log(otherField.fieldName);


    // 	//create validation for this field and run it
    // 	this.validator.validateField(curRow, curRow.errors, fieldObj.fieldName, fieldObj.validationRules, this.objectCollection);
    // 	//create validation for OTHER field and run it
    // 	this.validator.validateField(curRow, curRow.errors, otherField.fieldName, otherField.validationRules, this.objectCollection);
    // 	//console.log(curRow.errors);

    // 	//if EITHER field has errors - both have errors
    // 	if (curRow.errors[fieldObj.fieldName] || curRow.errors[otherField.fieldName]) {
    // 		curRow.errors[fieldObj.fieldName] = this.lang.getVal("field_must_be_unique");
    // 		curRow.errors[otherField.fieldName] = this.lang.getVal("field_must_be_unique");
    // 	}
    // 	//console.log(curRow.errors);
    // 	//console.log("saving " + fieldObj.attrs.joinedWithField);
    // 	//run save for other field
    // 	this.saveField(curRow, otherField);
    // }
    // if (fieldObj.attrs?.validateAndSaveOtherField && !curRow.errors[fieldObj.fieldName]) {
    // 	const otherField = this.collectionFields.find(it => it.fieldName == fieldObj.attrs.validateAndSaveOtherField);
    // 	this.validator.validateField(curRow, curRow.errors, otherField.fieldName, otherField.validationRules, this.objectCollection);
    // 	this.saveField(curRow, otherField);
    // }


    if (this.updateRowOverride) {



      curRow.isRowSubmittable = this.validator.hasNoErrors(curRow.errors);
    }

  }

  resetOtherField(curObj: any, fieldObj: any, isNew: boolean) {
    let otherField = this.collectionFields.find(it => it.fieldName == fieldObj.savingProps.fieldName);
    if (otherField) {
      curObj[fieldObj.savingProps.fieldName] = 0;
      curObj[otherField.attrs.displayStringFieldName] = "";

      if (isNew) {
        this.validateEntireForm();
      }
      else {
        this.validateField(curObj, otherField.fieldName);
      }
    }
  }
  saveAnotherField(curObj: any, fieldObj: any, isNew: boolean) {
    let otherField = this.collectionFields.find(it => it.fieldName == fieldObj.savingProps.fieldName);
    if (otherField && !curObj.errors[fieldObj.fieldName]) {
      if (isNew) {
        //
      }
      else {
        this.saveField(curObj, otherField, true);
      }
    }
  }


  async avoidDuplication(curRow: any, fieldObj: any) {
    const fieldName = fieldObj.fieldName;

    if (this.duplicateWarningFields.includes(fieldName)) {
      //open modal to ask if to save?
      if (
        this.objectCollection &&
        this.objectCollection.length &&
        this.objectCollection.find(it => {
          return (it[fieldName] == curRow[fieldName] && it.id != curRow.id);
        })
      ) {	//need to stop saving and open confirm modal!
        const doDuplicate = await this.modalService.openMulti("confirm",
          {
            actionLang: this.lang.getVal("confirm_duplicate"),
            objectName: curRow.name || (this.searchableFields.length ? curRow[this.searchableFields[0]] : "")
          });
        return !doDuplicate;

      }
    }
    return false;
  }

  async saveField(curRow: any, fieldObj: any, ignoreSavingProps: boolean = false, ignoreDuplicateWarning: boolean = false) {
    let fieldName = fieldObj.fieldName;

    if (this.updateRowOverride) { return; }
    //Should I save?
    if (!ignoreSavingProps && fieldObj.savingProps) {

      this[fieldObj.savingProps.funcName](curRow, fieldObj, false);	//field order for these functions MUST always be row,fieldObj,isNew
      if (fieldObj.savingProps.noSave) {
        return;
      }
    }

    let doStopSaving = await this.uniqueValueConfirmHandle(curRow, fieldObj);
    if (doStopSaving) { return; }



    if (curRow.errors[fieldName]) {return;}

    if (!ignoreDuplicateWarning && await this.avoidDuplication(curRow, fieldObj)) {
      //Stops the saving in either:
      //1. A duplicate value was FOUND and the modal was opened and is waiting a decision (checkDuplicateForField)
      //2. The modal has returned the answer "yes, please duplicate" (ignoreDuplicateWarning)
      return;
    }

    let res: any;
    if (["image","file"].includes(fieldObj.type)) {
      let file = (curRow.filesObj && curRow.filesObj[fieldName]) ? curRow.filesObj[fieldObj.fieldName] : undefined;
      // let formData = this.apiService.createFormUpload({ tableName: this.tableName, id: curRow.id, fieldName }, { newValue: file });
      res = await this.apiService.save_gen_field_file(this.tableName,curRow.id,fieldName,file);
    }
    else if (fieldObj?.attrs?.updateFuncName) {
      res = await this.apiService.post(fieldObj?.attrs?.updateFuncName,{ ...curRow, ...this.crSendDataObj() });
    }
    else {
      res = await this.apiService.save_gen_field(this.tableName, curRow.id, fieldName, curRow[fieldName]);
    }

    this.htmlNormalization(curRow);

    let message = "";
    if (res.success) {

      if(this.tableName=="contactways" && res?.contactway_identifier){
        curRow.contactway_identifier=res.contactway_identifier;
        this.store.updGenItemRowField(this.tableName, curRow.id, "contactway_identifier", curRow.contactway_identifier, this.crSendDataObj());
      }

      this.store.updGenItemRowField(this.tableName, curRow.id, fieldName, curRow[fieldName], this.crSendDataObj());
      message = this.lang.getVal("update_successfuly");
      // this.prepForStatic(curRow);
      this.search();


      if(this.tableName=="patients_sms" && fieldName=="notifiy" && curRow?.notifiy=="yes" && curRow?.notification_default=="email"){
        curRow.notification_default="sms";
        const fieldObj=this.collectionFields.find(f=>f.fieldName=="notification_default");
        if(fieldObj){
          this.saveField(curRow,fieldObj);
        }

      }
      if(["inquirers","patients"].includes(this.tableName)){
        curRow.updated_at=moment.utc().format(ConfigDefinitions.momentDateFormat);
      }

      this.rowValidChange.emit(this.objectCollection);
    }
    else {
      message = curRow[fieldName] + " " + this.lang.getVal("already_exists") + "!";
      curRow.errors[fieldName] = message;
    }
    this.modalService.openToast(message);
    this.changeHappened.emit(this.objectCollection);
  }

  // openAddModal(){	//COME BACK!!!
  // }
  prepNewRow() {
    this.isNewOpen = true;
    this.newRow = Object.assign({}, this.defaultNewRow);

    if(this.tableName=="headtures"){
      if (this.filterField) {
        this.newRow[this.filterField] = this.filterFieldValue;
      }
      let filterFieldApiObject = {};
      if (this.filterFieldApiObjectPreset) {
        filterFieldApiObject = { ...filterFieldApiObject, ...this.filterFieldApiObjectPreset };
      }
      if (this.filterFieldApiObject) {
        filterFieldApiObject = { ...filterFieldApiObject, ...this.filterFieldApiObject };
      }
      if (Object.keys(filterFieldApiObject).length) {
        this.newRow = { ...this.newRow, ...filterFieldApiObject };
      }
    }

    if (this.newRowTags) {
      this.newRowTags.reset();
    }
    if (this.newRowAssoc) {
      this.newRowAssoc.reset();
    }
    this.collectionFields.filter(fieldObj => (fieldObj.type == "date" && !fieldObj.attrs.avoidDateReset)).forEach(fieldObj => {
      this.newRow[fieldObj.fieldName] = this.store.formatDate(new Date(), true);
      this.genTableService.updDateField(this.newRow, fieldObj.fieldName);
    })
    this.newRow.errors = {};
    this.isFormSubmittable = false;

    //try get new focus
    let autoFocusField = this.collectionFields.find(f => f.attrs?.autofocus);
    if (autoFocusField) {
      this.focusOnRowField(this.newRow, autoFocusField);
    }



  }

  async uniqueValueConfirmHandle(curRow: any, fieldObj: any) {	//return false means CONTINUE SAVING
    if (!fieldObj.validationRules.default) { return false }	//this field is not a default=="yes" field - saving is ok

    console.log("uniqueValueConfirmHandle");

    const fieldName = fieldObj.fieldName;
    if (curRow[fieldName] != "yes") { return false }	//my value is not "yes" - no conflict, saving is ok
    const otherRow = this.objectCollection.find(it => {
      return (it[fieldName] == curRow[fieldName] && it.id != curRow.id);
    });
    if (!otherRow) { return false }	//no other with "yes" - saving is ok

    const isConfirm = await this.modalService.openMulti("confirm",
      {
        actionLang: this.lang.getVal("confirm_change_default")
      }
    );
    //console.log(curRow, otherRow, isConfirm);

    curRow[fieldName] = "no";
    if (!isConfirm) { return true }	//user chose NOT to change default to "yes" - prevent saving

    otherRow[fieldName] = "no";
    this.saveField(otherRow, fieldObj);
    curRow.errors[fieldName] = null;
    curRow[fieldName] = "yes";
    return false;	//user allowed the default change - saving is ok
    // if (curRow.id) {
    // 	this.saveField(curRow, fieldObj)
    // }
    // else {
    // 	this.validateEntireForm();
    // }

  }



  validateEntireForm(curRow: any = null, fieldObj: any = null) {


    let fields: any = [...this.collectionFields];
    if (this.newRow && this.tableName === "bankaccounts") {
      fields = this.collectionFields.map(field => ({ fieldName: field.fieldName, validationRules: { ...field.validationRules } }));
      if (this.newRow.is_foreign === "yes") {
        fields.find(it => it.fieldName === "bank_code").validationRules = {};
        fields.find(it => it.fieldName === "branch_id").validationRules = {};
        fields.find(it => it.fieldName === "foreign_bank").validationRules = { not_empty: null };
      }
    }

    if(this.tableName=="grouppatients"){
      this.newRow.errors.patient_id=null;
      //right now, the fields have patient_id
      if(this.newRow.user_id){
        //remove patient_id, add user_id, same validation rules
        fields=[...fields.filter(field=>field.fieldName!="patient_id"),{fieldName: "user_id",validationRules: { 'is_legit_fuzzy_value': true, unique: true }}];
      }
    }
    // console.log(fields);


    // const otherFieldToUse = this.collectionFields.find(fieldObj => fieldObj.attrs?.otherFieldToUse);
    // if (otherFieldToUse) {
    // 	this.newRow.errors[otherFieldToUse.fieldName] = null;
    // 	this.newRow.errors[otherFieldToUse.attrs.otherFieldToUse.fieldName] = null;
    // 	fields = fields.map(fieldObj => {
    // 		if (fieldObj.attrs?.otherFieldToUse && curRow[fieldObj.attrs.otherFieldToUse.fieldName]) {
    // 			return fieldObj.attrs.otherFieldToUse;
    // 		}
    // 		return fieldObj;
    // 	})
    // }


    this.isFormSubmittable = this.validator.validateEntireFormGen(this.newRow, fields, this.objectCollection);
    //console.log(this.newRow.errors);

    // if (otherFieldToUse && this.newRow.errors[otherFieldToUse.attrs.otherFieldToUse.fieldName]) {
    // 	this.newRow.errors[otherFieldToUse.fieldName] = this.newRow.errors[otherFieldToUse.attrs.otherFieldToUse.fieldName]
    // }
    if(this.tableName=="grouppatients" && this.newRow.errors.user_id){
      this.newRow.errors.patient_id=this.newRow.errors.user_id;
    }

    // console.log(this.newRow);




    if (fieldObj && fieldObj.savingProps && fieldObj.savingProps.noSave) {
      this[fieldObj.savingProps.funcName](curRow, fieldObj, false);	//field order for these functions MUST always be row,fieldObj,isNew
    }

  }

  async send_invite(curRow:any,fieldObj:any){
    curRow[fieldObj.fieldName+'_isSpin']=true;
    await this.apiService.post("send_invite",curRow,"id");
    curRow[fieldObj.fieldName+'_isSpin']=false;
    this.modalService.openToast(this.lang.getVal("sent_successfully"));
  }

  async updateRow(curRow: any) {
    if (!this.updateRowOverride) { return; }
    curRow.isUpdatingSpinner=true;
    const res:any=await this.apiService.post(this.updateRowOverride,curRow);
    curRow.isUpdatingSpinner=false;
    let message = "";
    if (!res.success) {
      this.modalService.openToast(this.lang.getVal("save_failed"));
      return;
    }
    this.htmlNormalization(curRow);

    //expect server to return fields to update
    // this.collectionFields.forEach(fieldObj=>{
    // 	let fieldName=fieldObj.fieldName;
    // 	if(res[fieldName]){
    // 		curRow[fieldName]=res[fieldName];
    // 	}
    // })
    this.store.detectMixedListChangeNeeded(this.tableName, curRow.patient_id);
    this.store.siteDataUpdateRow(this.tableName, res);
    await this.getItems();
    this.changeHappened.emit(this.objectCollection);

    this.modalService.openToast(this.lang.getVal("update_successfuly"));
  }

  crSendDataObj() {
    if (this.filterField) {
      return { [this.filterField]: this.filterFieldValue };
    }
    return null;
  }

  async addNewRow() {
    if (this.isSaving) { return; }
    if (this.duplicateWarningFields.length) {
      let fieldName = this.duplicateWarningFields[0];
      let fieldObj = this.collectionFields.find(it => it.fieldName == fieldName);
      if (fieldObj && await this.avoidDuplication(this.newRow, fieldObj)) {
        //Stops the saving in either:
        //1. A duplicate value was FOUND and the modal was opened and is waiting a decision (checkDuplicateForField)
        //2. The modal has returned the answer "yes, please duplicate" (ignoreDuplicateWarning)
        return;
      }
    }

    for (let fieldObj of this.collectionFields) {
      let doStopSaving = await this.uniqueValueConfirmHandle(this.newRow, fieldObj);
      if (doStopSaving) { return; }
    }


    let message = '';

    if (this.filterField) {
      this.newRow[this.filterField] = this.filterFieldValue;
    }
    let filterFieldApiObject = {};
    if (this.filterFieldApiObjectPreset) {
      filterFieldApiObject = { ...filterFieldApiObject, ...this.filterFieldApiObjectPreset };
    }
    if (this.filterFieldApiObject) {
      filterFieldApiObject = { ...filterFieldApiObject, ...this.filterFieldApiObject };
    }
    if (Object.keys(filterFieldApiObject).length) {
      this.newRow = { ...this.newRow, ...filterFieldApiObject };
    }



    let newRowNoErrors = Object.assign({}, this.newRow);
    delete newRowNoErrors.errors;

    //prep upl files
    let filesObj = {};
    this.collectionFields.forEach(fieldObj => {
      let fieldName = fieldObj.fieldName;
      if (["image","file"].includes(fieldObj.type)) {
        let file = (this.newRow.filesObj && this.newRow.filesObj[fieldName]) ? this.newRow.filesObj[fieldObj.fieldName] : undefined;
        filesObj[fieldName] = file;
      }
    });

    this.isSaving = true;
    let res:any;
    if (Object.keys(filesObj).length) {
      res =await this.apiService.save_gen_item_files({ mode: "add", tableName: this.tableName, ...newRowNoErrors ,...filesObj});
    }
    else {
      res = await this.apiService.save_gen_item(this.tableName, newRowNoErrors);
    }

    this.isSaving = false;
    if (res.id) {
      this.postAddTags(res);
      this.insertNewRow(res);
      message = this.lang.getVal("saved_successfully");
    }
    else {
      message =  this.lang.getVal(res?.mes || "save_failed");
    }
    this.modalService.openToast(message);

  }
  postAddTags(result: any) {
    const fieldObj = this.collectionFields.find(f => f.type == "tags-ac");
    if (!fieldObj) { return; }
    if (!result?.tags || !result.tags?.length) { return; }
    result.tags.forEach(tagRow => {
      this.store.addGenItemRow("object_to_tags", tagRow);
    })
  }
  postDeleteTags(item_id: any) {
    const fieldObj = this.collectionFields.find(f => f.type == "tags-ac");
    if (!fieldObj) { return; }
    const toDelRows = this.store.getSiteDataTable("object_to_tags").filter(row => {
      return row.item_id == item_id && row.item_type == fieldObj.attrs.tagItemType;
    });
    toDelRows.forEach(row => {
      this.store.delGenItemRow("object_to_tags", row.id);
    })
  }

  insertNewRow(result: any) {
    console.log(result);

    this.newRow.id = result.id;
    this.collectionFields.forEach(field => {
      if (field.attrs.displayStringFieldName) {
        this.newRow[field.attrs.displayStringFieldName] = result[field.attrs.displayStringFieldName];
      }
      if (field.attrs?.updatedByServer) {
        this.newRow[field.fieldName] = result[field.fieldName];
        console.log(field,this.newRow);
        
      }
    });
    this.newRow.errors = {};
    this.prepRow(this.newRow)
    // console.log(this.newRow);
    this.htmlNormalization(this.newRow);

    if(this.expandOnLoad && this.eventsService.isMobile){
      this.newRow.isExpanded=true;
    }

    this.objectCollection.unshift(this.newRow);
    this.objectCollectionShown.unshift(this.newRow);
    this.store.addGenItemRow(this.tableName, this.newRow, this.crSendDataObj());
    // this.search();
    // this.isNewOpen = false;



    //if addOnlyMode - close this modal and return value!
    if (this.isCheckItems) {
      this.newRow.checked = true;
      if(this.tableName==="contactways" && this.filterFieldApiObject?.contactway_identifier==="email" && this.newRow.contactway_identifier!=="email"){
        this.newRow.checked=false;
      }
      this.emitCheckedItemsChanged();
    }
    if (this.addOnlyMode || this.addOnlyRestOpened) {
      this.itemAdded.emit(this.newRow);
    }
    else {	//if listening to itemAdded - will close the modal containing this gen-table so no need for prepNewRow
      this.prepNewRow();
    }
    this.changeHappened.emit(this.objectCollection);
    this.changeItemsShownNonScroll();
  }

  async delete(curRow: any) {	//delete row
    const confirmDelete = await this.modalService.openMulti("confirm",	//open confirm modal
      {
        //the name to present to the user is either the "name" field of the row or the first searchable field of the row
        objectName: curRow.name || (this.searchableFields.length ? curRow[this.searchableFields[0]] : "")
      });
    if (!confirmDelete) { return; }	//if confirmation failed - return

    const delObjId = curRow.id;
    curRow.isDeleting=true;
    const res:any=await this.apiService.delete_gen_item(this.tableName, delObjId);
    curRow.isDeleting=false;
    if (res.success) {
      this.postDeleteTags(delObjId);
      this.objectCollection = this.objectCollection.filter(it => it.id != delObjId);
      this.store.delGenItemRow(this.tableName, delObjId, this.crSendDataObj());
      this.search();
      this.changeHappened.emit(this.objectCollection);
    }
    else {
      let message = this.lang.getVal("delete_failed");
      this.modalService.openToast(message);
    }
    //attach api delete, remove and re-search()
  }

  paginate(direction: number) {
    this.paginationObj.curPage += direction;
    this.getItems();
  }
  clientPaginate(direction: number) {
    this.clientPagiObj.curPage += direction;
  }

  emptyShownRows() {
    this.objectCollectionShown = [];
    this.changeItemsShownNonScroll();
  }
  headturesInsert(row: any, fieldObj: any) {
    let cliniqData = this.store.getCliniqDataOrExit();

    const info: string[] = [];
    info.push((cliniqData.title ? cliniqData.title + " " : "") + cliniqData.name);
    if (cliniqData.profession) {
      info.push(cliniqData.profession);
    }
    if (cliniqData.license_number) {
      info.push(this.lang.getVal("license_number") + " " + cliniqData.license_number);
    }
    if (cliniqData.vat_number) {
      info.push(this.lang.getVal(cliniqData.tax_account_type) + " " + cliniqData.vat_number);
    }
    if (cliniqData.city) {
      info.push(cliniqData.street + " " + cliniqData.house + " " + cliniqData.city + " " + cliniqData.zip);
    }
    if (cliniqData.phone) {
      info.push(this.lang.getVal("phone") + " " + cliniqData.phone);
    }
    if (cliniqData.fax) {
      info.push(this.lang.getVal("fax") + " " + cliniqData.fax);
    }
    if (cliniqData.email) {
      info.push(this.lang.getVal("email") + " " + cliniqData.email);
    }


    row[fieldObj.fieldName] = info.join("\n");
  }

  async buttonFieldClick(rowObj: any, fieldObj: any) {
    let fields = {...(fieldObj.attrs.fieldsToUpdate || {})};
    if (fieldObj.attrs.rowVariableName) {
      fields[fieldObj.attrs.rowVariableName] = rowObj;
    }
    // if(fieldObj?.attrs?.modal==AddInvoiceComponent && fieldObj.attrs?.fieldsToUpdate?.resource=="patient"){
    if(fieldObj?.attrs?.modal=="add-invoice" && fieldObj.attrs?.fieldsToUpdate?.resource=="patient"){
      fields.description = rowObj.name +  this.store.getPatientAndMaybeIdNumber(rowObj.id,true) + "-" + this.lang.getVal("treatment");

      if(this.tableName=="grouppatients"){
        console.log(rowObj);

        const patient=this.store.getSiteDataTable("patients").find(it=>it.id==rowObj.patient_id);
        fields[fieldObj.attrs.rowVariableName] = patient;
        fields.description = patient.name +  this.store.getPatientAndMaybeIdNumber(patient.id,true) + "-" + this.lang.getVal("treatment");

      }

      return this.modalService.openMulti("add-invoice",fields);
    }

    if(["grouppatients"].includes(this.tableName) && fieldObj?.attrs?.modal==="patient"){
      const patient = await this.store.getPatient(rowObj.patient_id);
      return this.store.openEditPatient(patient);
    }


    this.modalService.openMulti(fieldObj.attrs.modal,fields);
  }

  async headerModalOpenBtnClick(btnObj: any) {	//click one of the modal opener btns
    let fieldsToUpdate = { ...btnObj.fieldsToUpdate };	//get the preset fields and vals to update
    if (btnObj.dataVariablesFields) {	//if defined, try to attach data variables that were inputted into this table
      btnObj.dataVariablesFields.forEach(fieldName => {	//iterate field names
        if (this.dataVariables && this.dataVariables[fieldName] !== undefined) {	//if dataVariable found - attach it
          fieldsToUpdate[fieldName] = this.dataVariables[fieldName];
          console.log(fieldName,this.dataVariables[fieldName]);

        }
      })
    }

    if (btnObj?.addFilterFieldValueFromTable) {
      fieldsToUpdate.filterFieldValue = this.filterFieldValue;
    }

    if (btnObj.sendCheckedItems) {	//if requested the checked objects - add them as "checkedItems"
      fieldsToUpdate.checkedItems = this.objectCollection.filter(row => row.checked);
    }

    if(btnObj?.fieldsToUpdate?.faqSend){
      fieldsToUpdate.dataVariables={faqRows:this.objectCollection.filter(row => row.checked)};
    }

    if(btnObj.funcToRunOnReturn=="sendMultipleFinresources" && this.filterField=="user_id"){
      this.sendMultipleFinresources([],this.filterFieldValue);
      return;
    }
    if(btnObj.funcToRunOnReturn=="printMultipleFinresources"){
      this.printMultipleFinresources();
      return;
    }

    const retValue:any = await this.modalService.openMulti(btnObj.modal,fieldsToUpdate);	//open the modal
    if (retValue && btnObj?.funcToRunOnReturn) {
      this[btnObj.funcToRunOnReturn](retValue);
    }
  }

  prepRow(row: any) {
    //find of collection holding fields (select and button-group)
    const fieldsToChange = ["select", "button-group"];
    this.collectionFields.filter(field => field?.attrs?.staticOnEdit && fieldsToChange.includes(field.type)).forEach(field => {
      //fieldName_staticDisplayValue
      const find = field.optionsCollection.find(it => it.value == row[field.fieldName]);
      row[field.fieldName + "_staticDisplayValue"] = find?.lang || find?.name || "";
    })
    //find for fuzzies
    const fuzzyFields = ["fuzzy"];
    this.collectionFields.filter(field => field?.attrs?.staticOnEdit && fuzzyFields.includes(field.type)).forEach(field => {
      //fieldName_staticDisplayValue

      const find = this.store.searchSiteDataItem(row[field.fieldName], field.attrs.fuzzySearchableClasses);
      //console.log(row, field.fieldName, find);
      row[field.fieldName + "_staticDisplayValue"] = find?.name || row[field.fieldName] || "";

      if(!find && this.tableName=="grouppatients" && field.fieldName=="patient_id"){
        const findOther = this.store.searchSiteDataItem(row.user_id, ["sub_users"]);
        if (findOther) {
          row[field.fieldName + "_staticDisplayValue"] = findOther.name;
        }
      }


      // if (!find && field.attrs?.otherFieldToUse) {
      // 	const findOther = this.store.searchSiteDataItem(row[field.attrs.otherFieldToUse.fieldName], field.attrs.otherFieldToUse.fuzzySearchableClasses);
      // 	if (findOther) {
      // 		row[field.fieldName + "_staticDisplayValue"] = findOther.name;
      // 	}
      // }
    })

    const staticFields = ["static"];
    this.collectionFields.filter(field => field?.attrs?.tableName && staticFields.includes(field.type)).forEach(field => {

      const find = this.store.searchSiteDataItem(row[field.fieldName], [field.attrs.tableName]);
      row[field.fieldName + "_staticDisplayValue"] = find?.name || row[field.fieldName] || "";

    })

    const fuzzyApiFields = ["fuzzy-api"];
    this.collectionFields.filter(field => field?.attrs?.staticOnEdit && fuzzyApiFields.includes(field.type)).forEach(field => {
      //fieldName_staticDisplayValue
      row[field.fieldName + "_staticDisplayValue"] = row[field?.attrs?.displayStringFieldName] || row[field.fieldName] || "";
    })


    //change isCreditable for finResource_list
    if (this.tableName == "finResource_list") {
      row.isCreditable = true;
      if (row.doctype.startsWith("credit_") || row.doctype == "proforma") {
        row.isCreditable = false;
      }
      else {
        const findCreditRow = this.objectCollection.find(finRow => {
          return finRow?.original_id == row.id && finRow.doctype == "credit_" + row.doctype;
        });
        if (findCreditRow) {
          row.isCreditable = false;
        }
      }
    }

  }


  async openAddFuzzyModal(rowObj: any, fieldObj: any, isNew: boolean = false) {	//when clicking the "plus" btn next to an autocomplete field (ie. adding a new value to the collection this field is choosing from)

    const newRow: any = await this.modalService.openMulti("gen-table-add-item",{   //will receive either NULL if nothing happened or object of new inserted row!
      tableName: fieldObj.attrs.tableName,
      fuzzyName: rowObj[fieldObj.fieldName + "_fuzzy_val3465"], //fuzzyName is the actual written string in the input, will be attached to the "name" field of the new row
    });
    if (!newRow) { return };	//no new row or just cancelled

    //update the row (field and display string)
    rowObj[fieldObj.fieldName] = newRow.id;
    rowObj[fieldObj.attrs.displayStringFieldName] = newRow.name;

    //continue to validations and saves
    if (isNew) {
      this.validateEntireForm();
    }
    else {
      this.validateField(rowObj, fieldObj.fieldName);
      this.saveField(rowObj, fieldObj)
    }
    this.tryToFocusOnNextField(rowObj, fieldObj, isNew);	//continue to focus
  }


  chooseAll(isChecked: boolean) {
    this.objectCollection.forEach(row => row.checked = false);
    this.objectCollectionShown.forEach(row => row.checked = isChecked);

    this.emitCheckedItemsChanged();
  }
  emitCheckedItemsChanged() {	//emit all the checked rows
    this.hasCheckedItems=!!this.objectCollection.filter(row => row.checked).length;
    this.checkedItemsChanged.emit(this.objectCollection.filter(row => row.checked));
  }

  async downloadAndViewResource(row:any){
    row.pdfViewSpin=true;
    const doctype = row?.doc_type ? "document" : row.doctype;
    // const res:any=await this.apiService.download("print_finresource",{resource_type:"document",...pluck(row,"id,patient_id")});
    const res:any=await this.apiService.download("print_make_finresource",{doctype,id:row.id});
    row.pdfViewSpin=false;
    if(!res?.size){return this.store.downloadFailedModal();}
    this.apiService.previewDownload(res);

    // if(res){
    //   const fieldsToUpdate:any={blob:res}
    //   if(row.doc_type=="html" && row.signed=="yes"){
    //     fieldsToUpdate.printObj=row;
    //   }
    //   this.modalService.open("pdf-view",fieldsToUpdate);
    // }
  }

  //download and email
  async downloadFinResource(row: any, arrIds: any[] = null) {

    let typeId = row.type_id;
    let type = row.type;
    let downloadField = this.collectionFields.find(f => f.fieldName == "download");
    if (downloadField && downloadField.attrs?.downloadType) {
      typeId = row.id;
      type = downloadField.attrs?.downloadType;
    }
    if (row.doctype) {
      typeId = row.id;
      type = row.doctype;
    }
    let ext = "pdf";

    // let obj: any = { id: typeId };
    // if (arrIds) {
    //   obj.emailIds = arrIds;
    //   obj.by_email = "yes";
    // }
    // if (row.patient_id) { obj.patient_id = row.patient_id }
    // if (row.payor_id) { obj.payor_id = row.payor_id }
    // if (row.contact_id) { obj.contact_id = row.contact_id }
    // if (row.user_id) {
    //   obj.user_id = row.user_id;
    //   // obj.by_email="yes";
    // }
    // //selectedHeadtureId
    
    

    row.downloadSpin = true;
    // const sendObj:any = { id:obj.id,doctype:type }; //,headture_id:this.headtureRow.id
    const sendObj:any = { id:typeId,doctype:type }; //,headture_id:this.headtureRow.id

    const cc=this.store.getCliniqDataOrExit();
    if((["invoice","just_invoice",].includes(type) || ( type=="receipt" && cc.withoutvat=="yes" ) ) && Number(row.total)>25000 && !row?.israel_invoice && cc?.enable_israel_invoice=="yes"){
      const res=await this.apiService.post("get_israel_invoice_number",{id:row.id,type});
      this.modalService.openToast(res.message);
      if(res?.israel_invoice){
        if(row.patient_id){
          const updObj={id:row.patient_id,israel_invoice:res.israel_invoice};
          this.store.updatePatient(updObj as StorePatient);
        }
        sendObj.forceRecreatePdf=true;
      }
    }

    if (arrIds) {
      sendObj.emailIds = arrIds;
      sendObj.by_email = "yes";
    }

    this.store.downloadToastFunc(async ()=>{
      const res=await this.apiService.download("print_make_finresource",sendObj);
      row.downloadSpin = false;
      if(!res?.size){return this.store.downloadFailedModal();}
      this.modalService.openToast(this.lang.getVal("saved_successfully"))

      if (this.tableName == "documents") {
        row.signed = "yes";
        this.store.updGenItemRowField(this.tableName, row.id, "signed", row.signed, this.crSendDataObj());
        this.search();
      }
      let name = (row?.doc_type && row?.doc_type == "upload") ? row.doc_name : `${type}_${typeId}_copy.${ext}`;
      this.apiService.downloadFile(res, name);
    })
  }

  async renew(row: any) {
    row.renewSpin = true;
    const res:any=await this.apiService.post("renew_prescription",row,"id");
    row.renewSpin = false;
    if (res.id) {
      this.newRow = { ...this.store.removeDecItem(res) };
      this.insertNewRow(res);
    }
  }

  async duplicate(row: any) {
    row.dupSpin = true;
    const res:any=await this.apiService.post("duplicate_role",row,"id");
    row.dupSpin = false;
    if (res.id) {
      this.newRow.name = res.name;
      this.insertNewRow(res);
    }
    //duplicate_role
  }

  documentAction(row: any) {
    if (row.for_signing == "yes" && row.signed == "no") { return; }
    let newRow = { ...row, mode: "update" };
    if (newRow.signed == "yes") {

      newRow.id = 0;
      newRow.mode = "add";
      newRow.signed = "no";
      newRow.performValidation = true;
    }
    this.modalService.openMulti("add-document", {
      documentObj: newRow,
      performValidation: row.signed == "yes",
      patient_id: newRow.patient_id
    });
    //if signed - open add-document with duplicated row (id:0)
    //if not signed - open add-document with row
    //does save_finResource_ajax understand it's in add or  update? (by id?)
  }

  async downloadAndEmail(row: any) {	//download and email this row
    let fieldName = "";	//choose the field's name
    //console.log(row);
    if(row.user_id){
      this.downloadFinResource(row,[]);
      return;
    }

    if (row.patient_id) { fieldName = "patient_id" }
    if (row.payor_id) { fieldName = "payor_id" }
    if (row.contact_id) { fieldName = "contact_id" }

    const arr: any = await this.modalService.openMulti("gen-table-display", {	//open the modal to choose the contactways
      tableName: 'contactways',
      filterField: fieldName,
      filterFieldValue: row[fieldName],	//filter by this row's value in the fieldName (ex. patient_id=6)
      fieldNameInItemToDisplay: "contactway",	//the name of the field to show as the selected row
      isCheckItems: true,
    });
    if (arr && arr.length) {	//if chose any items - download finResource
      console.log(arr);

      this.downloadFinResource(row, arr);
    }
  }

  async sendMultipleFinresources(arrContactways: any[] = [],user_id:number=null) {
    //get chosen items

    const finresources = this.objectCollection.filter(row => row.checked).map(row => ({ id: row.id, doctype: row.doctype || "document" }));
    if(!finresources.length){return;}
    const contactway_ids = arrContactways.map(row => row.id);
    this.isSendingMulti=true;
    const res:any=await this.apiService.post("email_multiple_finresources",{finresources, contactway_ids,user_id});
    this.modalService.openToast(this.lang.getVal("sent_to"));
    this.isSendingMulti=false;
  }

  yesNoClick(curRow:any,fieldObj:any,isNewRow:boolean){
    const fieldName=fieldObj.fieldName;
    curRow[fieldName]=curRow[fieldName]=="yes" ? "no" : "yes";
    if(isNewRow){
      this.validateEntireForm(curRow,fieldObj);
    }
    else{
      this.validateAndSaveField(curRow,fieldObj);
    }
    if(fieldObj?.unique_for_value){
      if(curRow[fieldName]=="yes"){

        this.objectCollection.forEach(row=>{
          if(row.id==curRow?.id){return;}
          if(row[fieldName]=="no"){return;}
          row[fieldName]="no";
          this.validateAndSaveField(row,fieldObj);
        })

        if(!isNewRow && this.newRow?.[fieldName]){
          this.newRow[fieldName]="no";
          this.validateEntireForm(this.newRow,fieldObj);
        }
      }
    }
  }
  async printMultipleFinresources() {
    const selectedDocuments=this.objectCollection.filter(row => row.checked);
    if (!selectedDocuments.length) { return }
    for(let obj of selectedDocuments){
      const type = obj.doctype || "document";

      await this.store.downloadToastFunc(async ()=>{
        // const res:any=await this.apiService.download("print_finresource",{...obj,resource_type:type});
        const res:any=await this.apiService.download("print_make_finresource",{id:obj.id,doctype:type});
        let name = obj?.doc_type=="upload" ? obj.doc_name : `${type}_${obj.id}_copy.pdf`;
        this.apiService.downloadFile(res, name);
      })
    }
  }

  newRowAddAssoc(vals: any[], fieldObj: FieldGen) {
    this.newRow[fieldObj.fieldName] = vals.map(it => it.id);
    this.validateEntireForm();
  }

  async assocAddItem(obj: any, curRow: any, fieldObj: FieldGen) {
    //update api
    const item = { user_id: obj.id, item_id: curRow.id, item_type: "patient", active: "yes" };
    curRow[fieldObj.fieldName] = [...curRow[fieldObj.fieldName], obj.id];
    const res:any=await this.apiService.save_gen_item("object_to_sub_users", item);
    this.checkIfNeedInquirerStatusUpdate(res,curRow);
    this.modalService.openToast(this.lang.getVal("assigned_to_assoc"));
  }
  async assocDeleteItem(id: any, curRow: any, fieldObj: FieldGen) {
    //update the api
    //console.log(id);
    //console.log(curRow[fieldObj.fieldName]);

    const item = { user_id: id, item_id: curRow.id, item_type: "patient" };
    curRow[fieldObj.fieldName] = curRow[fieldObj.fieldName].filter(it => it != id);
    const res:any=await this.apiService.post("delete_assoc",item);
    this.checkIfNeedInquirerStatusUpdate(res,curRow);
    this.modalService.openToast(this.lang.getVal("unassigned_to_assoc"));
  }
  checkIfNeedInquirerStatusUpdate(res:any,row:any){
    if(this.tableName=="inquirers"){
      if(res?.motion){
        row.inquiry_status_id=res.motion.inquiry_status_id;
      }
    }
  }


  ////////////////////infi scrollbar
  isTableScrollable:boolean=false;
  eachH:number=30;
  maxShow:number=15;
  scrollPadTop:number=51+31+87+this.eachH;
  expandedRowHeight:number=87;
  @ViewChild("itemsDiv") itemsDiv:ElementRef;
  @ViewChild("scollContainer") scollContainer:ElementRef;
  itemsShown:any[]=[];
  scrollViewHeight:number=this.eachH*this.maxShow+this.scrollPadTop;
  scrollTotalHeight:number=this.scrollViewHeight;
  scrollTop:number=0;

  onSc(ev:any){
    this.scrollTop=ev.target.scrollTop;

    this.changeItemsShownNonScroll(this.scrollTop);
  }
  changeItemsShownNonScroll(st:number=0){

    let firstItemNum=Math.floor(st/this.eachH);
    let lastItemNum=firstItemNum+this.maxShow;

    if(lastItemNum>=this.objectCollectionShown.length){
      lastItemNum=this.objectCollectionShown.length;
      firstItemNum=lastItemNum-this.maxShow;
    }

    this.itemsShown=this.objectCollectionShown.filter((it,ind)=>ind>=firstItemNum && ind<lastItemNum);

    const expandedNum=this.itemsShown.filter(it=>it.isExpanded).length;

    this.scrollViewHeight=this.eachH*this.maxShow+this.scrollPadTop+expandedNum*this.expandedRowHeight;
    this.scrollTotalHeight=this.eachH*this.objectCollectionShown.length+this.scrollPadTop+expandedNum*this.expandedRowHeight;

    if(this.itemsDiv){
      this.zone.runOutsideAngular(()=>{
        this.itemsDiv.nativeElement.style.transform="translateY("+st+"px)";
        if(st==0){
          this.scollContainer.nativeElement.scrollTo(0,0);
          //console.log(this.scollContainer);
        }

      });
      // this.itemsDiv.nativeElement.style.transform="translateY("+st+"px)";
    }
  }

  changeToFilterAssocUsers(objs: any[]){
    this.filterAssocUserIds=objs.map(it=>it.id);
    this.runFilters();

  }
  changeToFilterTags(objs: any[]){
    this.filterTagIds=objs.map(it=>it.id);
    this.runFilters();

  }
  changeToFilterInquiryStatus(){
    this.runFilters();
  }

  processTaskChanges(res:any){
    if(res?.tasksChanged){
      this.getItems();
    }
  }

  async create_task_motion_by_patient_id(curRow:any,fieldObj:FieldGen){
    curRow[fieldObj.fieldName+'_isSpin']=true;
    const res=await this.apiService.post("create_task_motion_by_patient_id",{patient_id:curRow.id,inquiry_status_id:fieldObj?.attrs?.newValue});
    curRow[fieldObj.fieldName+'_isSpin']=false;
    if(res?.motion){
      curRow.inquiry_status_id=res.motion.inquiry_status_id;
    }
  }
  otherSiteDataTableValue(curRow:any,fieldObj:any){
    const item=this.store.searchSiteDataItem(curRow[fieldObj.attrs.fieldNameinThisTable],[fieldObj.attrs.otherTableName]);
    if(!item){return ""}
    return item[fieldObj.attrs.fieldNameInOtherTable];
  }
  otherSiteDataTableValueMinusField(curRow:any,fieldObj:any){
    const val1=this.otherSiteDataTableValue(curRow,fieldObj);
    let val2= curRow[fieldObj.attrs.minusField];
    if(!val1 || !val2){return ""}

    const isPerc=curRow?.unit==="percent";
    if(isPerc){
      val2= Number(val1)*val2/100;
    } 
    return val1-val2;
  }
  exportRows(){
    if(this.tableName=="patients"){
      this.store.downloadToastFunc(async ()=>{
        const res:any=await this.apiService.download("export_all_patients");
        this.apiService.downloadFile(res, "export.xlsx");
      });
      return;
    }



    const exportRows=[];

    const stringFields=["input","textarea","static","time","date"];
    const optsFields=["button-group","select"];
    const fuzzyField="fuzzy";
    const fuzzyApiField="fuzzy-api";
    const htmlField="wysiwyg";
    const multiField="autocomplete-multi";
    const tagsField="tags-ac";
    const inquiryStatusField="inquiry-status";

    const exportRow=[]; //"id"
    this.collectionFields.forEach(field=>{
      if([...stringFields,fuzzyField,fuzzyApiField,htmlField,...optsFields,multiField,tagsField,inquiryStatusField].includes(field.type)){
        // console.log(this.lang.getVal(field.langName));
        exportRow.push(this.lang.getVal(field.langName));
      }
    });
    exportRows.push(exportRow.map((it:any)=>it.replaceAll(",","")));


    // this.objectCollection.filter(it=>it.id==5051).forEach(row=>{
    // this.objectCollection.filter(it=>it.id==5178).forEach(row=>{
    this.objectCollection.forEach(row=>{
      // const exportRow=[String(row.id)];
      const exportRow=[];
      console.log(row);

      this.collectionFields.forEach(field=>{
        const fieldName=field.fieldName;
        // console.log(field);
        // console.log(row[fieldName]);

        let val="";
        switch(field.type){
          case fuzzyApiField:
            val=row[field.attrs?.displayStringFieldName] || "";
            exportRow.push(val);
            break;
          case fuzzyField:
            val="";
            const id=row[fieldName];

            if(id && field.attrs?.fuzzySearchableClasses){
              const className=field.attrs.fuzzySearchableClasses[0];
              const item=this.store.searchSiteDataItem(id,[className]);
              if(item){
                let displayField = this.searchSortService.siteDataSearchableFieldPerClass[className] || "name";
                val=item[displayField];
              }
            }

            // console.log("val",val);
            exportRow.push(val);
            break;
          case htmlField:
            val=row[fieldName] || "";
            val=this.store.strip_tags(val,true);

            // console.log("val",val);
            exportRow.push(val);
            break;
          case multiField:
            val="";
            const ids=row[fieldName];

            if(ids?.length){
              val=this.store.getSiteDataTable("sub_users").filter(it=>ids.includes(it.id)).map(it=>it.name).join("; ");
            }
            // console.log("val",val);
            exportRow.push(val);
            break;
          case tagsField:
            val="";

            const tagIds = this.store.getSiteDataTable("object_to_tags").filter(r => r.item_type == field.attrs.tagItemType && r.item_id == row.id).map(it=>it.tag_id);
            if (tagIds?.length) {
              val = this.store.getSiteDataTable("tags")
                .filter(it => tagIds.includes(it.id))
                .map(obj => obj.name).join("; ");
            }

            // console.log("val",val);
            exportRow.push(val);
            break;
          case inquiryStatusField:
            val=this.inquiry_statuses_names_obj[row.inquiry_status_id] || "";

            // console.log("val",val);
            exportRow.push(val);
            break;
        }

        if(stringFields.includes(field.type)){
          let val=String(row[fieldName]);
          if(row[fieldName]===null || row[fieldName]===undefined){
            val="";
          }

          if(val){
            if(field.type=="date"){
              val=this.store.getMomentDisplay(val);
            }
            if(field.type=="time"){
              // val=this.store.getMomentDisplay(val);
            }
          }
          // console.log("val",val);
          exportRow.push(val);
        }
        else if(optsFields.includes(field.type)){
          let val=row[fieldName] || "";
          if(val){
            const item=field.optionsCollection.find(it=>it.value==val);
            if(item){
              val=item.lang;
            }
          }
          // console.log("val",val);
          exportRow.push(val);
        }



      });

      console.log(exportRow);
      exportRows.push(exportRow.map((it:any)=>it.replaceAll(",","")));
      return;
    });

    // console.log(exportRows);
    // const exportText=exportRows.map(row=>row.join(",")).join("\r\n");
    const csvData=exportRows.map(row=>row.join(",")).join("\n");
    // console.log(exportText);

    this.store.downloadToastFunc(async ()=>{
      const res:any=await this.apiService.download("export_to_xlsx",{csvData} );
      this.apiService.downloadFile(res, "export.xlsx");
    });

    //const forFile=encodeURI("data:text/csv;charset=utf-8,"+exportText);
    //window.open(forFile);


  }

}

//new row - fuzzy - choose - what's next tabindex in field collection (field || add row)
//regular row - fuzzy - choose - what's next tabindex in field collection (field || next row first field)

